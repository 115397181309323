.image {
    max-height: 350px;
    border-width: 1px;
    border-color: #fff;
    border-radius: 4px;
    margin: 1rem;
}

p.description {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 1rem 1.25rem;
    text-align: center;
    max-width: 800px;
    background-color:#ebebeb;
    border-width: 1px;
    border-color: rgb(35, 199, 150);
    border-radius: 4px;
    border-style: solid;
    padding: 0.5rem;
}

.descriptionContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.imagesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

h2.braName {
    text-align: center;
    font-size: 1.5rem;
    color: #051757;
    font-weight: 600;
}

@media (max-width:388px) {
    h2.braName {
        font-size: 1.25rem;
    }
}

h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 1rem 1.25rem;
}

.recommendationText {
    color: #051757;
    font-weight: 500;
    font-size: 1.75rem;
    text-align: center;
}