a#basic-nav-dropdown:hover {
  color: #ff71c6 !important;
  text-decoration: none;
}

div button.too-big {
  min-width: 200px;
  border-color: #0a398f;
  border-width: 2.5px;
  margin: 0.5rem 1.5rem;
  padding: 0.25rem 1.5rem;
  font-size: 1.5rem;
  color: #fff;
  border-style: solid;
  border-radius: 4px;
  background: #0a398f;
}

div button.too-big:hover {
  border-color: #0b285e;
  background-color: #0b285e;
}

div button.too-small {
  min-width: 200px;
  border-color: #0086af;
  border-width: 2.5px;
  margin: 0.5rem 1.5rem;
  padding: 0.25rem 1.5rem;
  font-size: 1.5rem;
  color: #fff;
  border-style: solid;
  border-radius: 4px;
  background: #0086af;
}

div button.too-small:hover {
  border-color: #1b5f74;
  background-color: #1b5f74;
}

div button.correct {
  min-width: 200px;
  border-color: #008b62;
  border-width: 2.5px;
  margin: 0.5rem 1.5rem;
  padding: 0.25rem 1.5rem;
  font-size: 1.5rem;
  color: #fff;
  border-style: solid;
  border-radius: 4px;
  background-color: #008b62;
}

div button.correct:hover {
  border-color: #0d6d50;
  background-color: #0d6d50;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: #0a8560;
  border-color: #0a8560;
}

.text-primary {
  font-size: 16px;
  color: #051757 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #051757;
  font-weight: 500;
  margin-right: 20px;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-collapse {
  margin-left: 1rem;
}

@media (min-width: 1082px) {
  .navbar-brand {
    padding-left: 10%;
  }

  .navbar-expand-lg {
    padding-right: 10%;
  }
}

.h2,
h2 {
  font-size: 1.5rem;
  text-align: center;
}

@media (max-width: 575.98px) {
  .h1,
  h1 {
    font-size: 2rem;
  }
}
