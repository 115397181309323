@keyframes increase-decrease {
    from {
        transform: scale(0.8);
    }    
    to {
        transform: scale(1.1);
        color: #224adb;
    }
}

.container svg {
    vertical-align: middle;
    color: #3763FF;
    animation: increase-decrease 0.5s infinite alternate;
}

.container svg:hover {
    color: #224adb;
    transform: scale(1.2);
    transition: .3s ease;
    cursor: pointer;
}

.labelHeader div svg {
    float: right;
    color: #224adb;
}

.labelHeader {
    display: flex;
    justify-content: space-between;
}

.imageMapContainer {
    position: relative;
    max-width: 500px;
    margin: 1rem;
}

@media (min-width: 1160px) {
    .imageMapContainer {
      max-width: 350px;
    }
}

.container {
    position: relative;
}

.imageMapContainer img {
    display: block;
    width: 100%;
    height: auto;
    border-width: 1px;
    border-color: #fff;
    border-radius: 4px;
}

.labelArea {
    border-width: 3px;
    border-style: solid;
    border-color: #3763FF;
    border-radius: 4px;
    top: 0%;
    left: 0%;
    width: 100%;
    display:none;
    background-color: rgba(255, 255, 255, 0.90);
    transition: .5s ease;
    position: absolute;
    padding: 1rem;
    z-index: 1000;
}
