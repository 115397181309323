.chooseBraWrap {
    color: #fff;
    max-width: 600px;
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 5% 15%;
}

@media (min-width:730px) {
    .chooseBraWrap {
        padding: 5% 7%;
    }
}

.chooseBraWrap.home {
    background-color:#182144 ;
    border: 1px solid #182144;
    border-radius: 12px;
}

.form {
    width: 100%;
}

.fieldSet {
    border: 0;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: calc(10px + 10 * (100vw - 481px)/ 1319); 
}

.hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
}

.fields {
    display: flex;
    justify-content: space-between;
}

.dropdownField {
    display: flex;
    flex-direction: column;
    width: 30%;
    position: relative;
}

.toggleFields {
    display: flex;
    justify-content: center;
    width: 100%;
}

.dropdownField::after {
    font-weight: 900;
    content: "\276F";
    transform: rotate(0.25turn);
    color: #182144;
    right: 24px;
    top: 50px;
    position: absolute;
    pointer-events: none;
}

@media (max-width: 489.99px) {
    .dropdownField::after {
        content: "\25BE";
        transform: rotate(0);
        right: 12px;
        top: 48px;
    }
}

.toggleButton {
    width: inherit;
    color: #051757;
    background-color: rgba(255, 255, 255, 0.945);
    border-color: #0A8560;
    border-width: 2.5px;
    border-style: solid;
    line-height: 2.5rem;
    margin-top: 0;
    border-radius: 0;
}

.toggleButton.active {
    background: #0A8560;
    border-color: #0A8560;
    color: #fff
}

.toggleButton:first-child {
    border-radius: 4px 0 0 4px;
    border-right-width: 0;
}

.toggleButton:first-child:active {
    border-radius: 4px 0 0 4px;
}

.toggleButton:not(:first-child):not(:last-child) {
    border-right-width: 0;
    border-left-width: 0;
}

.toggleButton:last-child {
    border-radius: 0 4px 4px 0;
    border-left-width: 0;
}

.toggleButton:last-child:active {
    border-radius: 0 4px 4px 0;
}

.toggleButton:hover {
    transition: .3s ease;
    background: #0A8560;
    border-color: #0A8560;
    color: #fff;    
}

@media (min-width: 486px) {
    .toggleButton {
        /* min-width: 130px; */
        font-weight: 500;
        font-size: 1.25rem;
    }
} 

@media (max-width: 485.99px) {
    .toggleButton {
        /* min-width: 100px; */
        font-weight: 400;
        font-size: 1rem;
    }

    .chooseBraWrap {
        padding: 5%;
    }
}

@media (max-width: 360px) {
    .toggleButton {
        /* min-width: 90px; */
        font-weight: 400;
        font-size: 0.99rem;
    }
}


.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dropdown {
    display: flex;
    flex-direction: column;
}

.wizardHeading {
    color: #fff;
    font-weight: 500;
    font-size: 1.75rem;
    text-align: left;
}

h2.heading {
    font-weight: 500;
    font-size: 1.75rem;
    text-align: center;
}

@media (max-width:635.99px) {
    h2.heading {
        font-size: 1.5rem;
        text-align: left;
    }
}

.text { 
    color:#ffc3e7;
    margin: 1rem 0;
    line-height: 1.75rem;
    max-width: 474px;
}

.toggleButtonContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.actionButton {
    margin: 1rem 0;
    background-color: #C54492;
    border-color: #C54492;
    color: #fff;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    padding: 0.6rem;
    width: 100%;
    font-weight: 600;
}

.actionButton:hover {
    background-color: #0A8560;
    border-color: #0A8560;
    color: #ffffff;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    transform: scale(1.02);
}

select {
    color: #051757;
    background-color: rgba(255, 255, 255, 0.89);
    border: 2.5px solid #0A8560;
    border-radius: 4px;
    padding: .375rem .75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select:hover {
    background-color: rgb(255, 255, 255);
    border-color: rgb(13, 179, 118);
    transition: .3s ease;
    border-width: 2.5px;
    color: #01815b;
    cursor: pointer;
}

option {
    color: #051757;
}

@media (min-width: 490px) {
    select {
        font-weight: 500;
        font-size: 1.25rem;
    }
} 

@media (max-width: 489.99px) {
    select {
        font-weight: 400;
        font-size: 1rem;
    }
}

label.home {
        color: rgb(158, 255, 219);
        margin: 0.75rem 0 0.25rem 0; 
}

label.wizard {
    color: #01815b;
    margin: 0.75rem 0 0.25rem 0;
}