.header {
    padding: 1rem 0;
    box-shadow: 0px 1.5px rgba(82, 82, 82, 0.1);
}

.logo {
    max-width: 200px;
}

.navItem {
    color: #051757;
    font-size: 1rem;
    font-weight: 500;
    margin-right: 1.3rem;
    text-decoration: none;
}

.navItem:hover{
    color: #FF71C6 !important;
    text-decoration: none;
}

.active{
    color: #FF71C6 !important;
    text-decoration: none;
}

.activeNavItem {
    color: #333333;
}

.navItem:hover {
    color: #666666;
}

.navList {
    display: flex;
    list-style-type: none;
    padding: 8px 0;
    margin: 0;
}

.logoBar {
    display: flex;
    justify-content: space-around;
    padding: 0 1rem;
}

.sportsBraButton {
    color: #fff;
    background-color:  #0A8560;
    font-weight: 550;
    border-width: 0;
    border-radius: 0.15rem;
    padding: 0.25rem 0.75rem;
}

.sportsBraButton:active {
    border-width:2px;
    border-color: #FF71C6;    
    border-style: solid;
}

.sportsBraButton:hover {
    color: #fff;
    background: #FF71C6;
}

.guideLink {
    padding: 4px 24px;
}