.wizardFooter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    line-height: 2rem;
    text-align: center;
    background-color: rgb(7, 18, 63);
    color: #fff;
}

.footerItem {
    margin: 1rem;
}

@media (max-width: 480px) {
    .wizardFooter {
        font-size: 0.65rem;
        word-wrap: normal;
    }

    .footerItem {
        margin: 0 1rem;
    }
}

