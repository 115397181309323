 ol li.liHeader {
    color:  #0bb971;
    font-weight: 600;
}

ol li ol li {
    color: #051757;
    font-weight: 500;
}

@media (max-width: 450px) {
    ol li {
        font-size: 0.80rem;
    }
}

ol li {
    margin: 0.75rem 0;
}

.emphasis {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;
    padding: 0.5rem;
}

.note {
    margin: 0 0 0 1rem;
    color: #051757;
    text-align: left;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
}

@media (min-width: 664px) {
    .note {
        font-size: 0.85rem;
    } 
}

.noteIndicator svg {
    color:#0bb971 !important;
    animation: none;
}

.noteIndicator svg:hover {
    transform: none !important;
    cursor:none !important;
}

dl, ol, ul {
    margin: 0 1rem 1rem 0;
}