.wizardStepsContainer {
    display: flex;
    justify-content: space-evenly;
}

.actionButton {
    margin: 1rem 0;
    background-color: #0A8560;
    border-color: #0A8560;
    color: #ffffff;
    font-weight: 550;
    border-width: 1px;
    border-radius: 4px;
    padding: 0.5rem 1rem;
}

.actionButton:hover {
    background-color: rgb(9, 160, 104);
    color: #ffffff;
    border-color: rgb(9, 160, 104);
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
}

.secondaryButton {
    margin: 1rem 1rem 0 0;
    background-color: #ffffff00;
    color: #051757;
    border-width: 0;
    font-weight: 600;
}
.secondaryButton:hover {
    color: #0034f0;
}

.buttonGroup {
    float: right;
    margin: 0 2rem;
}

@media (max-width: 575.98px) { 
    .buttonGroup {
        margin: 0 .25rem;
    } 
}
