.wizardStepContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 0.25rem;
}

.wizardStepNumber {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid #051757;
    color: #051757;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wizardStepNumber:hover {
    background-color: #051757;
    color: #fff;
    cursor: pointer;
}

.wizardStepNumberActive {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid #051757;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #051757;
    color: #fff;
}

.wizardStepText {
    margin-top: 1rem;
    color: #051757;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 411.99px) {
    .wizardStepText {
        font-size: 0.8rem;
    }
}