.disclaimer {
    font-size: 0.7rem;
    line-height: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 10rem;
    flex: 1 1;
    justify-content: space-around;
    max-width: 1200px;
}

@media (max-width: 882px) {
    .disclaimer {
        margin: 0.5rem 2rem;
    }

}

.disclaimer p {
    color: rgb(71, 71, 71) !important;
}