.container {
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items:center;  
}

.content {
    flex-grow: 1;
    width: 100%;
    max-width: 1380px;
}