.container {
    border-radius: 0.5rem;
    background-color: #F5F5F5;
    margin-top: 2rem; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%
}

@media (max-width: 575.98px) { 
    .container {
        margin: 2rem 0.15rem;
    }   
}

.wizardHeading {
    color: #051757;
    font-weight: 500;
    font-size: 1.75rem;
    text-align: center;
    padding: 0 1rem;
}

@media (max-width:388px) {
    .wizardHeading {
        font-size: 1.5rem;
    }
}

.text { 
    color: #051757;
    padding: 2% 20%;
    width: 100%;
    text-align: center;
    margin: 1rem 0 1.5rem 0;
    background-color: rgba(195, 228, 212, 0.719);
}

@media (max-width: 713px) {
    .text {
        padding: 1rem 2rem;
        text-align: justify;
    }
}