.checkSupportContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-items: center;
}

.videoItem {
    align-self: center;
    padding: 1rem;
    max-width: 500px;
    height: 350px;
}

@media (max-width: 1017px) {
    .videoItem {
        max-width: 100%;
        height: 100%;
    }  
}

.movement {
 text-align: center;
 color: #051757;
 font-weight: 600;
}

.supportiveBraInfo {
    color: #051757;
    background-color:#ebebeb;
    margin: 1rem 1.25rem;
    border-color: rgb(35, 199, 150);
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    padding: 0.5rem;
}