.steps {
    color: #051757;
}

.emphasis {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;
    padding: 0.5rem;
}

.note {
    margin: 0 0 0 1rem;
    color: #051757;
    text-align: left;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
}

@media (min-width: 664px) {
    .note {
        font-size: 0.85rem;
    } 
}

.noteIndicator svg {
    color:#0bb971 !important;
    animation: none;
}

.steps ol li {
    margin: 0.8rem 0;
}