.braFittingTipsContainer {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    color: #051757;
    font-weight: 500;
    font-size: 1.75rem;
    margin-top: 0.5rem;
}

.modalContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}