.container {
    background: url('../assets/sports-bra-woman-bg.jpg') no-repeat center center fixed;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items:center;  
}

.content {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.tagLine {
    padding: 5rem;
}

@media (max-width: 652.80px) {
    .tagLine {
        padding: 2rem;
    }
}

.textContainer {
    flex: 1 1 0px;
    background-color: rgba(39, 193, 135, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.textContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 5rem;
    min-width: 325px;
    flex-grow: 2;
}

@media (max-width: 652.80px) {
    .textContentContainer {
        padding: 0 2rem;
    }
}

.textContainer h1 {
    max-width: 800px;
}

.tagLine span:first-child {
    color: #fff;
}
.tagLine span:last-child {
    color:rgb(11, 12, 76);
}

.logoContainer {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    background: #ffffffb0;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
}

.form {
    flex: 1 1 0px;
    background-color:rgba(4, 12, 44, 0.70);
    width: 100%;
    min-width: 632px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    margin: 0.75rem;
    height: 70px;
}

@media (max-width :1537.99px) {
    .image {
        height: 60px;
    }
}

@media (max-width: 1353.99px) {
    .tagLine {
        font-size: 2.2rem;
    }
}

@media (max-width: 972px) {
    .form {
        min-width: 487px;
    }
    .image {
        height: 60px;
    }
}


@media (max-width: 851px) {
    .image {
        height: 50px;
    }
}


@media (max-width:488px) {
    .form {
        min-width: fit-content;
    }
}

@media (max-width: 410px) {
    .image {
        height: 40px;
    }
    .tagLine {
        font-size: 1.5rem;
    }
}
