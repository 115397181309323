.aboutPage {
    display: flex;
    flex-direction: column;
    min-height: 780px;
    align-items: center;
}

@media (max-width: 575.98px) { 
    .aboutContainer {
        font-size: 1rem;
    }
    .h2, h2 {
        font-size: 1rem;
        font-weight: 500;
    }
    .aboutHeaderTitle {
        font-size: 1.65rem;
    }
}

.aboutHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem 1rem;
    width: 100%;
    margin-bottom: 1rem;
    background-color:rgb(0, 11, 52);
}

.aboutHeaderTitle {
    color:rgb(255, 255, 255);
    text-align: center;
    max-width: 1080px;
}

.aboutHeaderSubtitle {
    color:#ffaedf;
    padding: 0 1.5%;
    margin-top: 0.5rem;
    max-width: 900px;
}

p {
    color: rgba(5, 23, 87);
}

a {
    color: rgb(13, 179, 118);
}

a:hover {
    color:rgb(255, 113, 198);
}

.image {
    max-width: 100%;
    width: 700px; 
    object-fit: cover; 
    margin: 0 0 0.5rem 0;
}


.aboutContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 1207px;
}

.containerLeft {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
    min-width: 325px;
}

.imageContainer {
    flex: 1 1 0px;
    width: 100%;
    padding: 2rem;
    justify-content: center;
    align-items: center;
}

@media (max-width:488px) {
    .containerLeft {
        min-width: fit-content;
    }
}


@media (max-width: 972px) {
    .imageContainer {
        min-width: 487px;
        flex:none;
        text-align: center;
        margin: 0.25rem 2rem 0rem;
    }
    .containerLeft {
        max-width: 720px;
    }
}


@media (max-width:488px) {
    .imageContainer {
        min-width: fit-content;
        padding: 1rem;
    }
}