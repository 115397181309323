.formContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.inputField {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 500px;
}

.inputField:first-child {
    margin-top: 2rem;
}

form {
    max-width: 550px;
}

.inputField input {
    display: block;
    width: 100%;
    overflow: scroll;
    min-height: 40px;
    line-height: 20px;
    margin: 0.25rem 0 1rem 0px;
    border-radius: 4px;
    border-style: solid;
    border-color: rgb(5, 23, 87);
    border-width: 1.5px;
    padding: 0.5rem;
}

.inputField textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    min-height: 40px;
    line-height: 20px;
    border-radius: 4px;
    border-style: solid;
    border-color: rgb(5, 23, 87);
    border-width: 1.5px;
    padding: 0.5rem;
}

.inputField label {
    font-weight: 500px;
    font-size: 1rem;
    color: rgb(5, 23, 87);
}

.actionButton {
    margin-top: 1rem;
    background-color: rgb(5, 23, 87);;
    color: #fff;
    font-weight: 550;
    border-style: solid;
    border-color: rgb(5, 23, 87);
    border-width: 2px;
    border-radius: 4px;
    width: 100%;
    padding: 0.5rem 2rem;
    justify-self: flex-end;

}

.formContainer form button {
    float: right !important;
}

.actionButton:hover {
    background-color: #be3186;
    border-color: #be3186;
    color: #ffffff;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
}

.emailUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    height: 100%;
    width: 100%;
    padding: 2rem;
    background-color: rgb(241, 241, 241);
}

.title {
    text-align: center;
}

.contactPerson {
    margin: 2rem;
    text-align: center;
    font-size: 0.9rem;
    min-width: max-content;
}

.contactName {
    font-weight: 600;
}

.contactPerson span svg {
    color: rgb(13, 179, 118) !important;
    animation: none;
    margin: 0 0.25rem 0 0;
}

@media (max-width: 550px) {
    .inputField {
        min-width: 350px;
    } 
}

@media (max-width: 370px) {
    .inputField {
        min-width: 300px;
    } 
}

@media (max-width: 320px) {
    .inputField {
        min-width: 280px;
    } 
}


@media (max-width: 386.99px) {
    .contactName {
        margin: 0;
    }
    .contactPerson span {
        font-size: 0.8rem;
    }
}

a.emailLink {
    color: #000;
    text-decoration:none !important;
}

a.emailLink:hover {
    color: #be3186;
    text-decoration:none !important;
}